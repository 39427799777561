class CookieAlertSite {

    constructor() {
        //Variables
        this.cookieAlert = document.querySelector(".js-cookie-alert");
        this.ctaCookieAlert = document.querySelectorAll(".js-cta-cookie-alert");

        //Bind
        this.setCookie = this.setCookie.bind(this);
        this.getCookie = this.getCookie.bind(this);

        //Init
        this.init();
    }

    init() {}

    //Set site cookie
    setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    //Get site cookie
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

}

export default CookieAlertSite;
