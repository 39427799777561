class Header {

    constructor() {
        // Variables
        this.header = document.querySelector('.js-header');
        this.nav = document.querySelector('.js-menu');
        this.ctaNav = document.querySelector('.js-cta-mobile-menu');
        this.navEL = document.querySelectorAll(".js-nav-item");
        this.navOtherEl = document.querySelectorAll(".js-nav-other-item");
        $('.js-select2')
            .select2({
                minimumResultsForSearch: Infinity,
                templateResult: (selection) => {
                    if (!selection.id) {
                        return selection.text;
                    }

                    const baseUrl = "/static/images/flags";
                    const selectionWithImage = $(
                        '<span><img src="' + baseUrl + '/' + selection.element.value.toLowerCase() + '.png" class="img-flag" /> <span>' + selection.text + '</span></span>'
                    );

                    return selectionWithImage;
                },
                templateSelection: (selection) => {
                    if (!selection.id) {
                        return selection.text;
                    }

                    const baseUrl = "/static/images/flags";
                    const selectionWithImage = $(
                        '<span><img class="img-flag" /> <span></span></span>'
                    );

                    // Use .text() instead of HTML string concatenation to avoid script injection issues
                    selectionWithImage.find("span").text(selection.text);
                    selectionWithImage.find("img").attr("src", baseUrl + "/" + selection.element.value.toLowerCase() + ".png");

                    return selectionWithImage;
                },
            })
            .on('select2:close', this.currencyChanged);

        // Bind
        this.openCloseNav = this.openCloseNav.bind(this);
        this.currencyChanged = this.currencyChanged.bind(this);

        // Listener
        if (this.header) {
            this.ctaNav.addEventListener('click', this.openCloseNav);
            window.addEventListener("scroll", e => this.stickyHeader(e));
        }

        // Init
        if (this.header) this.stickyHeader();
        if (this.nav) this.init();
    }

    init() {
        setTimeout(() => {
            this.nav.classList.add("animOn");
        }, 500)
    }

    openCloseNav(index, e) {
        this.header.classList.toggle("open");
        this.nav.classList.toggle("open");
        this.ctaNav.classList.toggle("open");

        if (this.header.classList.contains("open")) {
            setTimeout(() => {
                TweenMax.staggerTo(this.navEL, 0.3, { css: { autoAlpha: 1, x: 0 }, ease: 'Power1.easeOut' }, 0.1);
                TweenMax.to(this.navOtherEl, 0.3, { delay: 1, css: { autoAlpha: 1 }, ease: 'Power1.easeOut' });
            }, 500)
        } else {
            setTimeout(() => {
                TweenMax.to(this.navEL, 0, { css: { autoAlpha: 0, x: -50 } });
                TweenMax.to(this.navOtherEl, 0, { css: { autoAlpha: 0 } });
            }, 500)
        }
    }

    stickyHeader() {
        if (window.pageYOffset >= 5) {
            this.header.classList.add('sticky');
        } else {
            this.header.classList.remove('sticky');
        }
    }

    currencyChanged(e) {
        e.target.closest("form").submit();
    }

}

export default Header;
