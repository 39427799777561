class Animator {

    constructor() {

        this.options = {
            delayBetweenAnimationsOfAGroupInSeconds: 0.2,
            intersectionObserverThreshold: 0.3,
            mediaBreakpoints: {
                'xs': 575,
                'sm': 767,
                'md': 991,
                'lg': 1199,
                'xl': 4999
            },
            animatedClassName: 'animated'
        }
        if(document.querySelector('.account') || document.querySelector('.reseller-banner__images-el') || document.querySelector('.blog-article__wysiwyg') || document.querySelector('.custom_text') || document.querySelector('.policies__container') || document.querySelector('.setup-success__col')) this.options.intersectionObserverThreshold = 0.001;
        this.currentViewportSize = Object.keys(this.options.mediaBreakpoints).find(key => window.innerWidth <= this.options.mediaBreakpoints[key]);
        this.animatorElements = [];
        this.initAnimationsFunctions();
        this.initAnimatedElements();

    }

    // Creates the the functions that can be executed on an animated element
    initAnimationsFunctions() {

        this.animationsFunctions = {

            addClass: (element, timeline, animationDelayInSeconds) => {

                timeline.to(element, 0, { className: `+=${this.options.animatedClassName}` }, `+=${element.getAttribute('data-animation-delay-in-seconds') ? element.getAttribute('data-animation-delay-in-seconds') : animationDelayInSeconds}`);

            },
            animateSVG: (element, timeline, animationDelayInSeconds) => {

                const maskPath = element.querySelector('.mask-path');

                timeline.to(maskPath, 1, {strokeDashoffset: -maskPath.getTotalLength()}, `+=${element.getAttribute('data-animation-delay-in-seconds') ? element.getAttribute('data-animation-delay-in-seconds') : animationDelayInSeconds}`);

            }

        }

    }

    createAnimatorElement(elementsToAnimate) {

        let animatorElement = {
            elementsToAnimate,
            intersectionObserver: new IntersectionObserver(this.handleIntersectionObserverEntry.bind(this), {
                threshold: this.options.intersectionObserverThreshold,
                // rootMargin is used to specify an offset so that we can delay an animations group
                rootMargin: elementsToAnimate[0].getAttribute('data-animation-offset') ? `0px 0px -${elementsToAnimate[0].getAttribute('data-animation-offset')}px 0px` : '0px 0px 0px 0px'
            })
        }

        animatorElement.intersectionObserver.observe(animatorElement.elementsToAnimate[0]);
        this.animatorElements.push(animatorElement);

    }

    // An element can have an order by viewport size, or a global order
    getElementSortOrder(element) {

        // Order by viewport size
        if (element.getAttribute('data-animation-orders')) {

            const animationOrderGroupOfCurrentViewportSize = JSON.parse(element.getAttribute('data-animation-orders'))
                                                                 .find(animationOrderGroup => animationOrderGroup.viewports.includes(this.currentViewportSize));

            if(animationOrderGroupOfCurrentViewportSize) {
                return animationOrderGroupOfCurrentViewportSize.order
            }

        }

        // Global order
        return element.getAttribute('data-animation-order');

    }

    getIsElementVisible(element) {

        // SVGs never have an offsetParent property, so we need to check the 'display' property to get it's visibility
        if(element.nodeName === 'svg') {
            return window.getComputedStyle(element).display !== 'none';
        }

        return element.offsetParent;

    }

    initAnimatedElements() {

        let groupsIds = [];
        const domElementsToObserve = Array.from(document.querySelectorAll('[data-animation-group-id]'))
                                        // We don't want to animate the hidden elements
                                        .filter(this.getIsElementVisible)
                                        .filter(elementToAnimate => {

                                            const elementToAnimateGroupId = elementToAnimate.getAttribute('data-animation-group-id');

                                            if (elementToAnimateGroupId) {
                                                if(groupsIds.includes(elementToAnimateGroupId)) {
                                                    return false;
                                                }
                                                else {
                                                    groupsIds.push(elementToAnimateGroupId);
                                                }
                                            }
                                            return true;
                                        })


        domElementsToObserve.forEach(domElementToObserve => {

            const animationGroupId = domElementToObserve.getAttribute('data-animation-group-id');

            if(!animationGroupId) {

                this.createAnimatorElement([domElementToObserve])

            }
            else {

                const domElementsToAnimate = Array.from(document.querySelectorAll(`[data-animation-group-id='${animationGroupId}']`))
                                            // Sorting the elements of a group by their data-animation-order or data-animation-orders property
                                            .filter(this.getIsElementVisible)
                                            .sort((a, b) => {
                                                let sortOrderA = this.getElementSortOrder(a);
                                                let sortOrderB = this.getElementSortOrder(b);

                                                return sortOrderA - sortOrderB;
                                            });

                this.createAnimatorElement(domElementsToAnimate)

            }

        })

    }

    handleIntersectionObserverEntry(entries) {

        const self = this;

        entries.forEach(entry => {

            if (entry.isIntersecting) {

                const animatorElement = self.animatorElements.find(animatorElement => animatorElement.elementsToAnimate[0] === entry.target);

                self.animateElements(animatorElement.elementsToAnimate);
                animatorElement.intersectionObserver.unobserve(entry.target);

            }

        });

    }

    animateElements(elements) {

        let timeline = new TimelineMax();

        elements.forEach((element, index) => {

            // Adding a delay to the animation in the timeline if it ain't the first element
            const animationDelayInSeconds = index > 0 ? this.options.delayBetweenAnimationsOfAGroupInSeconds : "0";

            // If the animation function is specified by the element and is defined in the initAnimationsFunctions, we call it
            if (element.getAttribute('data-animation-function') && typeof this.animationsFunctions[element.getAttribute('data-animation-function')] === 'function') {

                this.animationsFunctions[element.getAttribute('data-animation-function')](element, timeline, animationDelayInSeconds);

            }
            // Else we just call the default 'addClass' function
            else {

                this.animationsFunctions['addClass'](element, timeline, animationDelayInSeconds);

            }

        })

    }

}

export default Animator;
