/**
 * http://www.michaelbromley.co.uk/blog/90/simple-1d-noise-in-javascript
 */

class Simple1DNoise {

	constructor () {
		//variables
		this.MAX_VERTICES = 256;
		this.MAX_VERTICES_MASK = this.MAX_VERTICES -1;
		this.amplitude = 1;
		this.scale = 1;
		this.r = [];
		this.xMin = 0;
		this.xMax = 0;
		this.scaledX = 0;
		this.xFloor = 0;
		this.t = 0;
		this.tRemapSmoothstep = 0;

		for ( let i = 0; i < this.MAX_VERTICES; ++i ) {
			this.r[i] = Math.random();
		}

	}

	getVal(x) {
		this.scaledX = x * this.scale;
		this.xFloor = Math.floor(this.scaledX);
		this.t = this.scaledX - this.xFloor;
		this.tRemapSmoothstep = this.t * this.t * ( 3 - 2 * this.t );

		this.xMin = this.xFloor & this.MAX_VERTICES_MASK;
		this.xMax = ( this.xMin + 1 ) & this.MAX_VERTICES_MASK;

		this.y = this.lerp( this.r[this.xMin], this.r[this.xMax], this.tRemapSmoothstep);

		return this.y * this.amplitude;
	}

	lerp (a, b, t ) {
		return a * ( 1 - t ) + b * t;
	}

}

export default Simple1DNoise;