class BrowserInfo {

	static init() {
		// mobile
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) document.body.classList.add('mobile');
		else document.body.classList.add('allowHover');

		//browsers
		const ua = window.navigator.userAgent.toLowerCase();

		window.isIE       = ( /rv:11.0/i.test(navigator.userAgent)) ? true : false;
        window.isEdge     = ( window.navigator.userAgent.indexOf("Edge") > -1 ) ? true : false;
      	window.isOpera    = ( ua.indexOf("opr/") > -1 ) ? true : false;
      	window.isChrome   = !window.isOpera && ( ua.indexOf("chrome") > -1 ) ? true : false;
      	window.isSafari   = !window.isOpera && !window.isChrome && ( ua.indexOf("safari") > -1 ) ? true : false;
      	window.isFirefox  = ( ua.indexOf("firefox") > -1 ) ? true : false;

      	if (window.isIE)
      		document.body.classList.add('ie');
      	if (window.isEdge)
      		document.body.classList.add('edge');
      	if (window.isOpera)
           document.body.classList.add('opera');
        if (window.isChrome)
           document.body.classList.add('chrome');
      	if (window.isSafari)
      		document.body.classList.add('safari');
        if (window.isFirefox)
           document.body.classList.add('firefox');
	}

}
export default BrowserInfo;