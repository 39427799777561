window.REPTILE = window.REPTILE || {};

import '../sass/site.scss';
import Simple1DNoise from './modules/Simple1DNoise';
import Header from "./partials/Header";
import CookieAlertSite from "./partials/CookieAlertSite"
import BrowserInfo from "./tools/BrowserInfo";
import Animator from './modules/Animator';

class Main {

    constructor () {
    	BrowserInfo.init();
        document.addEventListener('DOMContentLoaded', this.ready.bind(this));

        //Variables noise element
        this.noiseItem = document.querySelectorAll(".js-noise-item");
        this.yMov = 0;
        this.perlin = [];
        this.y = new Simple1DNoise();
        this.amplitudeY = {min:3, max:4};
        const ratio = 5;
        this.amplitudeY.min *= ratio;
        this.amplitudeY.max *= ratio;

        //Bind
        this.makeSomeNoise = this.makeSomeNoise.bind(this);
    }

    ready() {
        window.REPTILE.Animator = new Animator();
        this.header = new Header();
        this.cookieAlertSite = new CookieAlertSite();

        // Generate custom value for noise element
        if(this.noiseItem.length) {
	        this.noiseItem.forEach((noiseItem,index) => {
	            this.perlin[index] = Math.random();
	        });
	        this.makeSomeNoise();
	    }
    }

    // Moove noise element
    makeSomeNoise(){
        if(this.y){
            this.noiseItem.forEach((noiseItem, index) => {
                this.perlin[index] += .01;  
                this.yMov = this.y.getVal(this.perlin[index]) * this.amplitudeY.max - this.amplitudeY.min;
                noiseItem.style.transform = 
                noiseItem.style.webkitTransform = "translateY("+(this.yMov.toFixed(2))+"px)";
            });
            requestAnimationFrame(this.makeSomeNoise);
        }
    }
}

new Main();